@tailwind utilities;
@tailwind components;
@tailwind base;

@import 'flowbite/dist/flowbite.css';

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes spin{
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@keyframes appear-left {
  from {
    opacity: 0;
    transform: translateX(-100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes appear-right {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes appear-bottom {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes appear-top {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes banner-left-to-right {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@keyframes banner-right-to-left {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@layer base {
  input[type="email"] {
    @apply appearance-none bg-transparent outline-none ring-0 focus:border-b-2 px-0 focus:border-neutralgray text-white border-b-2 border-neutralgray focus:outline-none focus:border-none focus:ring-0;
  }
}

.slick-slide > div {
  margin: 0px 20px;
  height: 288px;
  width: 288px;
};